/**
 * Package label comes from API as a string with " - " divider.
 * This method replaces this with new line.
 * @param label
 * @description To avoid v-html, we are using \n as a new line - remember about proper CSS class (eg. whitespace-pre-wrap)
 */
export default function formatPackageLabel(label: string): string {
  if (!label) {
    return ''
  }

  return label.replace(' - ', '\n')
}
